import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./routes/Router";
import { styled } from "@mui/system";
import "./index.css";
import { CssBaseline } from "@mui/material";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Toaster
        position="top-right"
        gutter={8}
        containerClassName="toast-container-all"
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "toast-container",
          duration: 5000,
          // Default options for specific types
          error: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    <CssBaseline />
    <Router />
  </React.StrictMode>
);
