import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import api from "./../services/api";
import { useEffect } from "react";

const values = {
  nome: "",
  sub_dominio: "",
  logo: "",
  db_host: "127.0.0.1",
  db_port: 3306,
  db_database: "",
  db_username: "",
  db_password: "",
  criarBanco: false,
  situacao: false,
  vencimento: "",
  pagamento: "",
};

function Modal({ open, setOpen, cliente, refreshClientes }) {
  const formik = useFormik({
    initialValues: values,
    onSubmit: (event) => {
      handleSubmit(event);
    },
  });

  useEffect(() => {
    if (!cliente) return;
    formik.setValues(cliente);
  }, [cliente]);

  function handleSubmit() {
    if (cliente) {
      // Se for edição
      api
        .put("/tenants/" + cliente.id, formik.values)
        .then((response) => {
          toast.success("Cliente editado com sucesso!");
          setOpen(false);
          refreshClientes();
        })
        .catch((error) => {
          toast.error("Erro ao editar o cliente");
          console.log(error);
        })
        .finally(() => formik.setSubmitting(false));
    } else {
      // Se for cadastro
      api
        .post("/tenants", formik.values)
        .then((response) => {
          toast.success("Cliente cadastrado com sucesso!");
          setOpen(false);
          refreshClientes();
        })
        .catch((error) => {
          toast.error("Erro ao cadastrar o cliente");
          console.log(error);
        })
        .finally(() => formik.setSubmitting(false));
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          formik.setValues(cliente);
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {cliente ? "Editar Cliente" : "Novo cliente"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="nome"
              value={formik.values?.nome}
              label="Nome da empresa"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: flex-mol"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="sub_dominio"
              value={formik.values?.sub_dominio}
              label="Subdomínio"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: flexmol.api.allmacoding.com"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="db_host"
              value={formik.values?.db_host}
              label="Host DB"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: 127.0.0.1"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="db_port"
              value={formik.values?.db_port}
              label="Porta DB"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: 3306"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="db_database"
              value={formik.values?.db_database}
              label="Nome do banco"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: flexmol"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="db_username"
              value={formik.values?.db_username}
              label="Username DB"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: root"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="db_password"
              value={formik.values?.db_password}
              label="Senha DB"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Ex: 123"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="vencimento"
              value={formik.values?.vencimento}
              label="Data de vencimento"
              type="date"
              fullWidth
              variant="standard"
              onChange={formik.handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              name="pagamento"
              value={formik.values?.pagamento}
              label="Data do pagamento"
              type="date"
              fullWidth
              variant="standard"
              onChange={formik.handleChange}
            />
            <label>Situação</label>
            <Checkbox
              name="situacao"
              checked={formik.values?.situacao}
              onClick={()=>{
                formik.setFieldValue('situacao', !formik.values?.situacao);
              }}
            />
            {!cliente && (
              <>
                <label>Criar Banco</label>
                <Checkbox
                  name="criarBanco"
                  checked={formik.values?.criarBanco}
                  onClick={()=>{
                    formik.setFieldValue('criarBanco', !formik.values?.criarBanco);
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpen(false);
                formik.setValues(cliente);
              }}
            >
              Cancelar
            </Button>
            <Button disabled={formik.isSubmitting} type="submit">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default Modal;
