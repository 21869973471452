import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import logo from "../../assets/logo192.png";
import { useNavigate } from "react-router-dom";
import api from "./../../services/api";
import { useEffect, useState } from "react";
import Modal from "./../../components/Modal";
import FullScreenLoader from "./../../components/FullScreenLoader";

const values = {
  nome: "",
  sub_dominio: "",
  logo: "",
  db_host: "127.0.0.1",
  db_port: 3306,
  db_database: "",
  db_username: "",
  db_password: "",
  criarBanco: false,
  situacao: false,
  vencimento: "",
  pagamento: "",
};

function Home() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState(values);

  useEffect(() => {
    loadClientes();
  }, []);

  function loadClientes(){
    setLoading(true);
    api
      .get("/tenants")
      .then((response) => {
        setClientes(response.data['data']);
      })
      .catch((error) => {
        console.log(error);
      }).
      finally(()=>setLoading(false));
  }

  return (
    <>
      <FullScreenLoader loading={loading} setLoading={setLoading}/>
      <Modal open={open} setOpen={setOpen} cliente={clienteSelecionado} refreshClientes={loadClientes}></Modal>
      <Fab
        color="primary"
        variant="extended"
        style={{
          margin: 10,
          top: "auto",
          right: 0,
          bottom: 0,
          left: "auto",
          position: "fixed",
        }}
        onClick={() => {
          setClienteSelecionado(null);
          setOpen(true);
        }}
      >
        Cadastrar
      </Fab>

      <Grid
        container
        spacing={3}
        style={{ margin: "12px", width: "auto" }}
      >
        {clientes.map((cliente) => {
          return (
            <Grid item key={cliente.id} xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  action={
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setClienteSelecionado(cliente);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <span>
                      <b>{cliente.nome}</b>
                    </span>
                  }
                  subheader={
                    "Último pagamento: " +
                    new Date(cliente.pagamento).toLocaleDateString("pt-br")
                  }
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={cliente.logo ?? logo}
                  alt="Paella dish"
                />
                <CardContent>
                  <p>
                    Nome do banco: <b>{cliente.db_database}</b>
                  </p>
                  <p>
                    Sub: <b>{cliente.sub_dominio}</b>
                  </p>
                </CardContent>
                <Chip
                  style={{ margin: 8 }}
                  label={cliente.situacao ? "ATIVO" : "INATIVO"}
                  color={cliente.situacao ? "success" : "error"}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default Home;
