import { Button, Grid, Input, Paper, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./../../services/api";
import { toast } from "react-hot-toast";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const CardLogin = styled("div")({
  background: "#302c2c",
  color: "#ffffff",
  width: "500px",
  height: "400px",
  borderRadius: "20px",
  marginTop: "150px",
  padding: "10px",
  marginLeft: "650px",
});

const Ilustracao = styled("img")({
  position: "absolute",
  width: "900px",
  marginTop: "50px",
});

const values = {
  email: "",
  senha: "",
};

function Login() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: values,
    onSubmit: (event) => {
      handleSubmit(event);
    },
  });

  function handleSubmit(event) {
    api
      .post("/login", formik.values)
      .then((response) => {
        console.log(response.data.access_token);
        localStorage.setItem("token", response.data.access_token);
        navigate("/home");
      })
      .catch((error) => {
        toast.error("Usuário inválido");
      })
      .finally(() => formik.setSubmitting(false));
  }

  return (
    <>
      <Grid container>
        {/* <SupervisorAccountIcon></SupervisorAccountIcon> */}
        <Ilustracao className="Ilustracao" src="/ilustracao.svg" alt="aa" />
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          color="white"
        >
          <CardLogin>
            <h1 style={{marginLeft: "50px", marginTop: "40px"}}>Login</h1>
            <Grid item>
              <TextField
                name="email"
                label="E-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                style={{ width: "350px", marginLeft: "50px"}}
              />
            </Grid>

            <Grid item style={{ marginTop: "20px",width: "350px", marginLeft: "50px"}}>
              <TextField
                name="senha"
                label="Senha"
                type="password"
                value={formik.values.senha}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Button
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
              style={{ marginTop: "30px", width: "350px", marginLeft: "50px"}}
            >
              Entrar
            </Button>
          </CardLogin>
        </Grid>
      </form>
    </>
  );
}

export default Login;
